<template>
    <div ref="container">
        <div v-if="!loading" style="height: calc(100vh - 64px)">
            <div
                class="tw-flex tw-items-center tw-justify-between tw-space-x-2 tw-p-4 tw-border-0 tw-border-solid tw-border-b tw-border-gray-300"
                :style="headerStyle"
            >
                <div class="tw-flex tw-items-center tw-space-x-2">
                    <v-btn v-if="saving" icon disabled>
                        <v-progress-circular
                            indeterminate
                            color="white"
                            width="1"
                            size="18"
                        ></v-progress-circular>
                    </v-btn>
                    <v-btn v-else icon @click="close" dark>
                        <v-icon>fal fa-arrow-left</v-icon>
                    </v-btn>
                    <v-autocomplete
                        v-model="form.customer_id"
                        :items="companies"
                        item-text="title_with_name"
                        item-value="id"
                        :error-messages="form.errors.get('customer_id')"
                        @input="form.errors.clear('customer_id')"
                        @change="handleCustomerChanged"
                        label="Company"
                        outlined
                        hide-details="auto"
                        :disabled="updatingCustomer || customerUpdated"
                        dense
                        dark
                        readonly
                    >
                        <template v-slot:append>
                            <v-icon
                                v-if="!updatingCustomer && !customerUpdated"
                            >fal fa-angle-down</v-icon>
                            <v-progress-circular
                                indeterminate
                                :size="18"
                                :width="1"
                                color="primary"
                                v-if="updatingCustomer"
                            />
                            <v-icon
                                v-if="customerUpdated"
                                color="success"
                                small
                            >fal fa-check</v-icon>
                        </template>
                    </v-autocomplete>
                    <v-autocomplete
                        v-model="form.customer_contact_id"
                        :items="contacts"
                        item-text="name_with_email"
                        item-value="id"
                        :error-messages="form.errors.get('customer_contact_id')"
                        @input="form.errors.clear('customer_contact_id')"
                        @change="handleContactChanged"
                        label="Contact"
                        outlined
                        hide-details="auto"
                        dense
                        dark
                        no-data-text="Select a company first."
                        readonly
                    >
                        <template v-slot:append>
                            <v-icon
                                v-if="!updatingContact && !contactUpdated"
                            >fal fa-angle-down</v-icon>
                            <v-progress-circular
                                indeterminate
                                :size="18"
                                :width="1"
                                color="primary"
                                v-if="updatingContact"
                            />
                            <v-icon
                                v-if="contactUpdated"
                                color="success"
                                small
                            >fal fa-check</v-icon>
                        </template>
                    </v-autocomplete>
                    <v-autocomplete
                        v-model="form.participants"
                        :items="participants"
                        item-text="name"
                        item-value="id_with_class"
                        :error-messages="form.errors.get('participants')"
                        @input="form.errors.clear('participants')"
                        @change="handleParticipantsChanged"
                        label="Participants"
                        outlined
                        hide-details="auto"
                        :disabled="updatingParticipants || participantsUpdated"
                        dense
                        multiple
                        small-chips
                        deletable-chips
                        dark
                        readonly
                    >
                        <template v-slot:append>
                            <v-icon
                                v-if="!updatingParticipants && !participantsUpdated"
                            >fal fa-angle-down</v-icon>
                            <v-progress-circular
                                indeterminate
                                :size="18"
                                :width="1"
                                color="primary"
                                v-if="updatingParticipants"
                            />
                            <v-icon
                                v-if="participantsUpdated"
                                color="success"
                                small
                            >fal fa-check</v-icon>
                        </template>
                    </v-autocomplete>
                    <v-autocomplete
                        v-model="form.ticket_department_id"
                        :items="departments"
                        item-text="title"
                        item-value="id"
                        :error-messages="form.errors.get('ticket_department_id')"
                        @input="form.errors.clear('ticket_department_id')"
                        @change="handleDepartmentChanged"
                        label="Department"
                        outlined
                        hide-details="auto"
                        :disabled="updatingDepartment || departmentUpdated"
                        dense
                        dark
                        readonly
                    >
                        <template v-slot:append>
                            <v-icon
                                v-if="!updatingDepartment && !departmentUpdated"
                            >fal fa-angle-down</v-icon>
                            <v-progress-circular
                                indeterminate
                                :size="18"
                                :width="1"
                                color="primary"
                                v-if="updatingDepartment"
                            />
                            <v-icon
                                v-if="departmentUpdated"
                                color="success"
                                small
                            >fal fa-check</v-icon>
                        </template>
                    </v-autocomplete>
                    <v-autocomplete
                        v-model="form.ticket_type_id"
                        :items="types"
                        item-text="title"
                        item-value="id"
                        :error-messages="form.errors.get('ticket_type_id')"
                        @input="form.errors.clear('ticket_type_id')"
                        @change="handleTypeChanged"
                        label="Type"
                        outlined
                        hide-details="auto"
                        :disabled="updatingType || typeUpdated"
                        dense
                        dark
                        no-data-text="Select department first"
                        readonly
                    >
                        <template v-slot:append>
                            <v-icon
                                v-if="!updatingType && !typeUpdated"
                            >fal fa-angle-down</v-icon>
                            <v-progress-circular
                                indeterminate
                                :size="18"
                                :width="1"
                                color="primary"
                                v-if="updatingType"
                            />
                            <v-icon
                                v-if="typeUpdated"
                                color="success"
                                small
                            >fal fa-check</v-icon>
                        </template>
                    </v-autocomplete>
                    <v-autocomplete
                        v-model="form.ticket_priority_id"
                        :items="priorities"
                        item-text="title"
                        item-value="id"
                        :error-messages="form.errors.get('ticket_priority_id')"
                        @input="form.errors.clear('ticket_priority_id')"
                        @change="handlePriorityChanged"
                        label="Priority"
                        outlined
                        hide-details="auto"
                        :disabled="updatingPriority || priorityUpdated"
                        dense
                        dark
                        readonly
                    >
                        <template v-slot:append>
                            <v-icon
                                v-if="!updatingPriority && !priorityUpdated"
                            >fal fa-angle-down</v-icon>
                            <v-progress-circular
                                indeterminate
                                :size="18"
                                :width="1"
                                color="primary"
                                v-if="updatingPriority"
                            />
                            <v-icon
                                v-if="priorityUpdated"
                                color="success"
                                small
                            >fal fa-check</v-icon>
                        </template>
                    </v-autocomplete>
                    <v-autocomplete
                        v-model="form.ticket_status_id"
                        :items="statuses"
                        item-text="title"
                        item-value="id"
                        :error-messages="form.errors.get('ticket_status_id')"
                        @input="form.errors.clear('ticket_status_id')"
                        @change="handleStatusChanged"
                        label="Status"
                        outlined
                        hide-details="auto"
                        :disabled="updatingStatus || statusUpdated"
                        dense
                        dark
                        readonly
                    >
                        <template v-slot:append>
                            <v-icon
                                v-if="!updatingStatus && !statusUpdated"
                            >fal fa-angle-down</v-icon>
                            <v-progress-circular
                                indeterminate
                                :size="18"
                                :width="1"
                                color="primary"
                                v-if="updatingStatus"
                            />
                            <v-icon
                                v-if="statusUpdated"
                                color="success"
                                small
                            >fal fa-check</v-icon>
                        </template>
                    </v-autocomplete>
                    <v-btn @click="init" :loading="loading">Refresh</v-btn>
                </div>
            </div>
            <div class="tw-flex tw-items-start" style="height: calc(100% - 73px);">
                <div class="tw-bg-white tw-h-full tw-flex-1 tw-p-4 tw-w-2/4">
                    <simplebar style="height: calc(100% - 45px);">
                        <h1 class="tw-font-semibold tw-text-xl">{{ form.title }}</h1>
                        <v-expansion-panels accordion flat v-model="panel">
                            <v-expansion-panel class="tw-border-0 tw-border-solid tw-border-b tw-border-gray-200">
                                <v-expansion-panel-header class="tw-pl-0 tw-pr-1 tw-font-bold">Description</v-expansion-panel-header>
                                <v-expansion-panel-content class="tw-mb-4">
                                    <simplebar style="height: calc(100vh - 444px);">
                                        <div v-html="form.description"></div>
                                    </simplebar>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                            <v-expansion-panel>
                                <v-expansion-panel-header class="tw-pl-0 tw-pr-1 tw-font-bold">Resolution</v-expansion-panel-header>
                                <v-expansion-panel-content class="tw-mb-4">
                                    <simplebar style="height: calc(100vh - 444px);">
                                        <div v-html="form.resolution"></div>
                                    </simplebar>
                                </v-expansion-panel-content>
                            </v-expansion-panel>                            
                        </v-expansion-panels>
                    </simplebar>
                    <div class="tw-flex tw-items-center tw-justify-between tw--mx-4 tw-border-0 tw-border-solid tw-border-t tw-border-gray-200 tw-p-4">
                        <div class="tw-text-sm tw-text-gray-500">{{ `#${ticket.number}` }}</div>
                        <div class="tw-flex tw-items-center tw-justify-end tw-space-x-2">
                            <attachments-button :attachments="ticket.attachments" />
                            <!--<services-button :ticket="ticket" :services="companyServices" />
                            <assets-button :ticket="ticket" :assets="companyAssets" />-->
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        icon
                                        small
                                        v-bind="attrs"
                                        v-on="on"
                                    >
                                        <v-icon small>fal fa-clock</v-icon>
                                    </v-btn>
                                </template>
                                <span>
                                    Received on {{ ticket.received_at_for_human }}<br />
                                    Processed on {{ ticket.created_at_for_human }}<br />
                                    Updated on {{ ticket.updated_at_for_human }}
                                </span>
                            </v-tooltip>
                        </div>
                    </div>
                </div>
                <div class="tw-h-full tw-flex-grow-0 tw-flex tw-flex-col tw-border-0 tw-border-solid tw-border-l tw-border-gray-300 tw-p-4 tw-bg-gray-50 tw-w-2/4">
                    <div class="tw-h-4/5 tw-flex-1 tw-flex tw-flex-col">
                        <div class="tw-flex-1 tw-h-2/5">
                            <simplebar class="tw-pb-4 tw-h-full" ref="threadsSimplebar">
                                <div class="tw-space-y-4">
                                    <v-timeline
                                        dense
                                        clipped
                                        align-top
                                    >
                                        <template v-for="log in ticket.logs">
                                            <v-timeline-item
                                                :key="log.id"
                                                fill-dot
                                                small
                                            >
                                                <template v-slot:icon>
                                                    <v-avatar size="26">
                                                        <img :src="log.creator_profile_image_url">
                                                    </v-avatar>
                                                </template>
                                                <v-card>
                                                    <div>
                                                        <conversation
                                                            v-if="log.type === 'conversation'"
                                                            :item="log"
                                                            :update-url="`conversations/${log.id}`"
                                                            :soft-delete-url="`conversations/${log.id}/soft-delete`"
                                                            :key="log.id"
                                                            :mailboxes="mailboxes"
                                                            :recipientsOptions="recipientsOptions"
                                                            :ticket="ticket"
                                                            @commentFormOpened="handleCommentFormOpened"
                                                            ref="conversation"
                                                        />
                                                        <div
                                                            v-else
                                                            class="tw-p-4"
                                                        >
                                                            <div>
                                                                <div class="tw-flex tw-items-center tw-justify-between">
                                                                    <v-menu open-on-hover offset-y>
                                                                        <template v-slot:activator="{ on, attrs }">
                                                                            <div 
                                                                                class="tw-text-base"
                                                                                v-on="on"
                                                                                v-bind="attrs"
                                                                            >
                                                                                <span v-if="log.children.length > 0" class="tw-text-sm">{{ log.creator_name }} &middot; <span class="tw-text-gray-500">{{ log.children[0].description }}</span></span>
                                                                                <span v-else>{{ log.body }}</span>
                                                                            </div>
                                                                        </template>
                                                                        <v-card>
                                                                            <v-list>
                                                                                <v-list-item>
                                                                                    <v-list-item-avatar>
                                                                                    <img
                                                                                        :src="log.creator_profile_image_url"
                                                                                        :alt="log.creator_name"
                                                                                    >
                                                                                    </v-list-item-avatar>

                                                                                    <v-list-item-content>
                                                                                    <v-list-item-title>{{ log.creator_name }}</v-list-item-title>
                                                                                    <v-list-item-subtitle>{{ log.creator_email }}</v-list-item-subtitle>
                                                                                    </v-list-item-content>
                                                                                </v-list-item>
                                                                            </v-list>
                                                                        </v-card>
                                                                    </v-menu>
                                                                    <div class="tw-text-xs">{{ log.created_at_with_time_for_human }}</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </v-card>
                                            </v-timeline-item>
                                        </template>
                                    </v-timeline>
                                </div>
                            </simplebar>
                        </div>
                        <div v-if="ticket.logs.findIndex((log) => log.type === 'conversation') === -1" class="tw-flex-grow-0 tw--mx-4 tw--mb-4 tw-bg-white tw-p-4 tw-border-0 tw-border-t tw-border-gray-200 tw-border-solid">
                            <comment-form
                                :mailboxes="mailboxes"
                                :ticket="ticket"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <v-overlay :value="loading" absolute>
            <v-progress-circular
                indeterminate
                size="64"
                width="1"
            ></v-progress-circular>
        </v-overlay>
    </div>
</template>

<script>
    import ClipboardJS from 'clipboard';
    import { mapGetters } from 'vuex';
    import Form from '@/utils/form';
    import Http from '@/utils/httpClient';
    import debounce from 'lodash.debounce';
    import Simplebar from 'simplebar-vue';
    import Conversation from './components/conversation.vue';

    export default {
        components: {
            Conversation,
            CommentForm: () => import('./components/comment-form.vue'),
            AttachmentsButton: () => import('./components/attachments-button.vue'),
            //AssetsButton: () => import('./components/assets-button.vue'),
            //ServicesButton: () => import('./components/services-button.vue'),
            Simplebar,
        },
        data() {
            return {
                panel: 0,
                loading: false,
                saving: false,
                updatingTitle: false,
                titleUpdated: false,
                updatingDescription: false,
                descriptionUpdated: false,
                updatingStaffNotes: false,
                staffNotesUpdated: false,
                updatingResolution: false,
                resolutionUpdated: false,
                updatingStatus: false,
                statusUpdated: false,
                updatingCustomer: false,
                customerUpdated: false,
                updatingContact: false,
                contactUpdated: false,
                updatingParticipants: false,
                participantsUpdated: false,
                updatingType: false,
                typeUpdated: false,
                updatingDepartment: false,
                departmentUpdated: false,
                updatingPriority: false,
                priorityUpdated: false,
                statuses: [],
                workflowStates: [],
                companies: [],
                contacts: [],
                participants: [],
                recipientsOptions: [],
                staff: [],
                types: [],
                cachedTypes: [],
                departments: [],
                priorities: [],
                templates: [],
                companyServices: [],
                companyAssets: [],
                mailboxes: [],
                prevRoute: null,
                form: new Form({
                    title: null,
                    description: null,
                    status: null,
                    customer_id: null,
                    customer_contact_id: null,
                    participants: [],
                    ticket_department_id: null,
                    ticket_type_id: null,
                    ticket_priority_id: null,
                }),
            }
        },
        computed: {
            ...mapGetters({
                ticket: 'ticket/current',
            }),
            headerStyle() {
                const priority = this.priorities.find((i) => i.id === this.ticket.priority_id);

                return {
                    backgroundColor: priority ? priority.color : '#555',
                }
            },
        },
        watch: {
            $route(to, from) {
                if (to.name === 'tickets.details' && to.params.ticketId !== from.params.ticketId) {
                    this.init();
                }
            }
        },
        beforeRouteEnter(to, from, next) {
            next((vm) => {
                vm.prevRoute = from;
            })
        },
        mounted() {
            this.init();
        },
        methods: {
            init() {
                this.loading = true;
                Http.get(`tickets/${this.$route.params.ticketId}/edit`, {
                        params: {
                            query: {
                                ticket: [
                                    'id',
                                    'title',
                                    'description',
                                    'status_id',
                                    'company_id',
                                    'company_contact_id',
                                    'department_id',
                                    'type_id',
                                    'participants',
                                    'source_type',
                                    'attachments',
                                    'priority_id',
                                    'links',
                                    'number',
                                    'contact',
                                    'company',
                                    'staff_notes',
                                    'resolution',
                                    'staff_only',
                                    'updated_at_for_human',
                                    'received_at_for_human',
                                    'created_at_for_human',
                                    'staff_only',
                                    'schedule_count',
                                    'company_assets',
                                    'company_services',
                                    'logs',
                                ],
                                company_asset: [
                                    'id',
                                    'title',
                                    'id_with_class',
                                    'staff_frontend_url',
                                ],
                                company_service: [
                                    'id',
                                    'title',
                                    'id_with_class',
                                    'staff_frontend_url',
                                ],
                                ticket_link: [
                                    'linkable',
                                ],
                                ticket_linkable: [
                                    'id',
                                    'id_with_class',
                                    'title',
                                    'staff_frontend_url',
                                ],
                                ticket_department: [
                                    'id',
                                    'title',
                                ],
                                ticket_type: [
                                    'id',
                                    'title',
                                    'department_id',
                                ],
                                ticket_priority: [
                                    'id',
                                    'title',
                                    'color',
                                ],
                                ticket_participant: [
                                    'id_with_class',
                                    'name',
                                    'email',
                                    'type',
                                ],
                                ticket_template: [
                                    'title',
                                    'description',
                                    'content',
                                ],
                                ticket_status: [
                                    'id',
                                    'title',
                                ],
                                mailbox_message: [
                                    'attachments',
                                ],
                                company: [
                                    'id',
                                    'title_with_name',
                                    'contacts',
                                    'default_contact',
                                ],
                                company_contact: [
                                    'id_with_class',
                                    'id',
                                    'name_with_email',
                                    'name',
                                    'email',

                                ],
                                attachment: [
                                    'file_name',
                                    'url',
                                    'download_url',
                                    'mime_type',
                                    'foreign_id',
                                    'is_inline',
                                ],
                                mailbox: [
                                    'id',
                                    'from_name',
                                    'from_address',
                                    'ticket_department_id',
                                ]
                            }
                        }
                    })
                    .then(({ data }) => {
                        this.$store.commit('ticket/setCurrent', data.ticket);
                        this.statuses = data.statuses;
                        this.companies = data.companies;
                        this.participants = data.participants;
                        this.staff = data.staff;
                        this.cachedTypes = data.types;
                        this.departments = data.departments;
                        this.priorities = data.priorities;
                        this.templates = data.templates;
                        this.mailboxes = data.mailboxes;
                        this.companyAssets = data.company_assets;
                        this.companyServices = data.company_services;
                        this.recipientsOptions = data.recipients;
                        this.types = this.cachedTypes.filter((type) => type.department_id === this.ticket.department_id);

                        const company = this.companies.find((company) => company.id === this.ticket.company_id);

                        if (company) {
                            this.contacts = company.contacts;
                        }

                        this.form.reset();
                        this.form.errors.clear();
                        this.form.set({
                            title: this.ticket.title,
                            description: this.ticket.description,
                            status: this.ticket.status_id,
                            customer_id: this.ticket.company_id,
                            customer_contact_id: this.ticket.company_contact_id,
                            ticket_department_id: this.ticket.department_id,
                            ticket_type_id: this.ticket.type_id,
                            ticket_priority_id: this.ticket.priority_id,
                            participants: this.ticket.participants.map((item) => item.id_with_class),
                            staff_notes: this.ticket.staff_notes,
                            resolution: this.ticket.resolution,
                        })
                        this.loading = false;

                        setTimeout(() => {
                            this.scrollDownThreadsContainer();
                            
                            // eslint-disable-next-line no-new
                            new ClipboardJS('.copy', {
                                text: function (trigger) {
                                    return trigger.getAttribute('aria-label');
                                },
                                container: this.$refs.container.$el
                            }).on('success', () => {
                                this.$root.$snackbar.open({
                                    text: 'Copied!',
                                    type: 'success',
                                });
                            })
                        }, 100)
                    })
                    .catch(() => {
                        this.close();
                        this.loading = false;
                    })
            },
            close() {
                this.$router.push({
                    name: 'tickets.index'
                })
            },
            scrollDownThreadsContainer(top) {
                setTimeout(() => {
                    const threadsContainer = this.$refs.threadsSimplebar.scrollElement;

                    if (!top) {
                        top = threadsContainer.scrollHeight;
                    }

                    threadsContainer.scrollTo({ top, behavior: 'smooth' });
                }, 100)
            },
            handleTitleChanged: debounce(function (title) {
                this.form.errors.clear('title');
                this.updatingTitle = true;
                Http.patch(`tickets/${this.$route.params.ticketId}/update-title`, { title })
                    .then(() => {
                        this.updatingTitle = false;
                        this.titleUpdated = true;
                        setTimeout(() => {
                            this.titleUpdated = false;
                            this.$emit('success');
                        }, 1000)
                    })
                    .catch(({ response }) => {
                        this.updatingTitle = false;
                        this.form.errors.record(response.data);
                    })
            }, 1000),
            handleDescriptionChanged: debounce(function (description) {
                if (!this.$route.params.ticketId) {
                    return;
                }

                this.form.errors.clear('description');
                this.updatingDescription = true;
                Http.patch(`tickets/${this.$route.params.ticketId}/update-description`, { description })
                    .then(() => {
                        this.updatingDescription = false;
                        this.descriptionUpdated = true;
                        setTimeout(() => {
                            this.descriptionUpdated = false;
                        }, 1000)
                    })
                    .catch(({ response }) => {
                        this.updatingDescription = false;
                        this.form.errors.record(response.data);
                    })
            }, 1000),
            handleStaffNotesChanged: debounce(function (staff_notes) {                
                if (!this.$route.params.ticketId) {
                    return;
                }

                this.form.errors.clear('staff_notes');
                this.updatingStaffNotes = true;
                Http.patch(`tickets/${this.$route.params.ticketId}/update-staff-notes`, { staff_notes })
                    .then(() => {
                        this.updatingStaffNotes = false;
                        this.staffNotesUpdated = true;
                        setTimeout(() => {
                            this.staffNotesUpdated = false;
                        }, 1000)
                    })
                    .catch(({ response }) => {
                        this.updatingStaffNotes = false;
                        this.form.errors.record(response.data);
                    })
            }, 1000),
            handleResolutionChanged: debounce(function (resolution) {
                if (!this.$route.params.ticketId) {
                    return;
                }

                this.form.errors.clear('staff_notes');
                this.updatingResolution = true;
                Http.patch(`tickets/${this.$route.params.ticketId}/update-resolution`, { resolution })
                    .then(() => {
                        this.updatingResolution = false;
                        this.resolutionUpdated = true;
                        setTimeout(() => {
                            this.resolutionUpdated = false;
                        }, 1000)
                    })
                    .catch(({ response }) => {
                        this.updatingResolution = false;
                        this.form.errors.record(response.data);
                    })
            }, 1000),
            handleStatusChanged(status) {
                console.log(status)
                this.updatingStatus = true;
                Http.patch(`tickets/${this.$route.params.ticketId}/status`, { ticket_status_id: status })
                    .then(() => {
                        this.updatingStatus = false;
                        this.statusUpdated = true;
                        setTimeout(() => {
                            this.statusUpdated = false;
                            this.$emit('success');
                        }, 1000)
                    })
                    .catch(({ response }) => {
                        this.updatingStatus = false;
                        this.form.errors.record(response.data);
                    })
            },
            handleCustomerChanged(id) {
                const customer = this.companies.find((customer) => customer.id === id);

                if (customer) {
                    this.contacts = customer.contacts;
                    this.participants = customer.contacts.concat(this.staff);
                }

                this.updatingCustomer = true;
                Http.patch(`tickets/${this.$route.params.ticketId}/update-customer`, { customer_id: id })
                    .then(() => {
                        this.updatingCustomer = false;
                        this.customerUpdated = true;
                        setTimeout(() => {
                            this.customerUpdated = false;
                            this.$emit('success');
                        }, 1000)
                    })
                    .catch(({ response }) => {
                        this.updatingCustomer = false;
                        this.form.errors.record(response.data);
                    })
            },
            handleContactChanged(id) {
                this.updatingContact = true;
                Http.patch(`tickets/${this.$route.params.ticketId}/update-contact`, { customer_contact_id: id })
                    .then(() => {
                        this.updatingContact = false;
                        this.contactUpdated = true;
                        setTimeout(() => {
                            this.contactUpdated = false;
                            this.$emit('success');
                        }, 1000)
                    })
                    .catch(({ response }) => {
                        this.updatingContact = false;
                        this.form.errors.record(response.data);
                    })
            },
            handleParticipantsChanged(participants) {
                this.updatingParticipants = true;
                Http.patch(`tickets/${this.$route.params.ticketId}/participants`, { participants })
                    .then(() => {
                        this.updatingParticipants = false;
                        this.participantsUpdated = true;
                        setTimeout(() => {
                            this.participantsUpdated = false;
                            this.$emit('success');
                        }, 1000)
                    })
                    .catch(({ response }) => {
                        this.updatingParticipants = false;
                        this.form.errors.record(response.data);
                    })
            },
            handleTypeChanged(ticket_type_id) {
                this.updatingType = true;
                Http.patch(`tickets/${this.$route.params.ticketId}/type`, { ticket_type_id })
                    .then(() => {
                        this.updatingType = false;
                        this.typeUpdated = true;
                        setTimeout(() => {
                            this.typeUpdated = false;
                            this.$emit('success');
                        }, 1000)
                    })
                    .catch(({ response }) => {
                        this.updatingType = false;
                        this.form.errors.record(response.data);
                    })
            },
            handleDepartmentChanged(ticket_department_id) {
                this.types = this.cachedTypes.filter((type) => type.department_id === ticket_department_id);

                this.updatingDepartment = true;
                Http.patch(`tickets/${this.$route.params.ticketId}/department`, { ticket_department_id })
                    .then(() => {
                        this.updatingDepartment = false;
                        this.departmentUpdated = true;
                        setTimeout(() => {
                            this.departmentUpdated = false;
                            this.$emit('success');
                        }, 1000)
                    })
                    .catch(({ response }) => {
                        this.updatingDepartment = false;
                        this.form.errors.record(response.data);
                    })
            },
            handlePriorityChanged(ticket_priority_id) {
                this.updatingPriority = true;
                Http.patch(`tickets/${this.$route.params.ticketId}/priority`, { ticket_priority_id })
                    .then(() => {
                        this.updatingPriority = false;
                        this.priorityUpdated = true;
                        setTimeout(() => {
                            this.priorityUpdated = false;
                            this.$emit('success');
                        }, 1000)
                        this.$store.commit('ticket/updateCurrentPriority', ticket_priority_id);

                        const priority = this.priorities.find((priority) => priority.id === ticket_priority_id);

                        if (priority) {
                            this.$store.commit('ticket/updateTicket', {
                                id: this.ticket.id,
                                props: {
                                    priority_id: priority.id,
                                    priority: priority,
                                }
                            })
                        }
                    })
                    .catch(({ response }) => {
                        this.updatingPriority = false;
                        this.form.errors.record(response.data);
                    })
            },
            handleCommentFormOpened() {
                const threadsContainer = this.$refs.threadsSimplebar.scrollElement;
                const top = threadsContainer.scrollTop + 300;

                this.scrollDownThreadsContainer(top);
            }
        }
    }
</script>
