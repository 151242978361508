<template>
    <v-hover>
        <div
            class="tw-flex"
        >
            <div
                class="tw-flex-grow-0 tw-flex-shrink-0"
                :class="{'tw-pl-4 tw-pt-4': parent !== null}"
                v-if="parent !== null"
            >
                <v-avatar
                    size="26"
                >
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attr }">
                            <img 
                                :src="item.creator_profile_image_url"
                                v-on="on"
                                v-bind="attr"
                            />
                        </template>
                        <span>{{ item.creator_name }}</span>
                    </v-tooltip>
                </v-avatar>
            </div>

            <div
                class="tw-flex-1"
            >
                <div
                    class="tw-p-4"
                    :class="{'tw-bg-white tw-rounded-t tw-border-0 tw-border-b tw-border-solid tw-border-gray-200': parent === null}"
                >
                    <div class="tw-flex tw-items-center tw-justify-between tw-mb-2 tw-text-base">
                        <div class="tw-flex tw-items-center">
                            <div>{{ item.creator_name }} <span v-if="parent === null">created a conversation</span></div>
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attr }">
                                    <v-icon
                                        small
                                        v-on="on"
                                        v-bind="attr"
                                        class="tw-ml-2 tw-mt-1"
                                        v-if="item.meta.recipients.length > 0"
                                    >fal fa-envelope</v-icon>
                                </template>
                                <span>Sent to {{ item.meta.recipients.map((item) => item.email).join(', ') }}</span>
                            </v-tooltip>
                        </div>
                        <div
                            class="tw-text-sm tw-flex tw-items-center tw-space-x-1"
                        >
                            <div class="tw-text-xs tw-text-gray-500">
                                {{ item.created_at_with_time_for_human }}
                            </div>
                            <template v-if="item.creator  && item.creator.id === me.id">
                                <span class="tw-text-gray-400">&bull;</span>
                                <a @click="edit">Edit</a>
                                <span class="tw-text-gray-400">&bull;</span>
                                <v-menu
                                    v-model="confirmDelete"
                                    :close-on-content-click="false"
                                    :close-on-click="false"
                                    offset-y
                                    :nudge-right="-260"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <a v-bind="attrs" v-on="on">Delete</a>
                                    </template>
                                    <v-card>
                                        <v-card-title class="tw-text-base">Are you sure?</v-card-title>
                                        <v-card-text>You are about to delete this conversation.</v-card-text>
                                        <v-card-actions>
                                            <v-spacer></v-spacer>
                                            <v-btn
                                                text
                                                @click="confirmDelete = false"
                                                :disabled="deleting"
                                            >Cancel</v-btn>
                                            <v-btn
                                                text
                                                color="red"
                                                :loading="deleting"
                                                @click="deleteConversation"
                                            >Yes, delete it.</v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </v-menu>
                            </template>
                        </div>
                    </div>
                    <div v-if="showEditForm && selectedConversation.id === item.id">
                        <div
                            class="tw-p-2 tw-rounded tw-border tw-border-solid tw-mb-2"
                            :class="editForm.errors.has('body') ? 'tw-border-red-400' : 'tw-border-gray-400 hover:tw-border-gray-600'"
                        >
                            <tiny-editor
                                v-model="editForm.body"
                                placeholder="Write your reply here..."
                                @input="editForm.errors.clear('body')"
                            />
                        </div>
                        <div v-if="editForm.errors.has('body')" class="tw-text-xs tw-text-red-400 tw-mb-4">
                            {{ editForm.errors.get('body') }}
                        </div>
                        <div class="tw-flex tw-items-center tw-justify-between">
                            <v-checkbox 
                                v-model="editForm.staff_only" 
                                label="Staff only"
                                class="tw-mr-2 tw-mt-0"
                                hide-details
                                dense
                            />
                            <div>
                                <v-btn
                                    text
                                    @click="cancelEdit"
                                    :disabled="updating"
                                    small
                                >Cancel</v-btn>
                                <v-btn
                                    text
                                    color="primary"
                                    :loading="updating"
                                    @click="update"
                                    small
                                >
                                    Save
                                    <template v-slot:loader v-if="updateSuccess">
                                        <v-icon
                                            color="success"
                                            dense
                                        >fal fa-check</v-icon>
                                    </template>
                                </v-btn>
                            </div>
                        </div>
                    </div>
                    <template v-else>
                        <div class="tw-text-sm">
                            <!--<h4 class="tw-mb-1" v-if="item.title" v-html="item.title"></h4>-->
                            <div v-html="item.body"></div>
                            <div v-if="item.meta.attachments.length > 0">
                                <h4 class="tw-mb-2">Attachments</h4>
                                <div class="tw-space-y-2">
                                    <a
                                        v-for="attachment in item.meta.attachments"
                                        :href="attachment.download_url"
                                        :key="attachment.id"
                                        class="tw-block tw-border tw-border-solid tw-border-gray-300 tw-py-2 tw-px-4 tw-rounded"
                                    >
                                        {{ attachment.file_name }}
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="tw-mt-2">
                            <v-tooltip top v-if="item.staff_only">
                                <template v-slot:activator="{on, attrs}">
                                    <v-icon 
                                        small
                                        v-on="on"
                                        v-bind="attrs"
                                    >fal fa-user-lock</v-icon>
                                </template>
                                <span>Staff only</span>
                            </v-tooltip>
                            <v-tooltip top v-if="!item.staff_only">
                                <template v-slot:activator="{on, attrs}">
                                    <v-icon 
                                        small
                                        v-on="on"
                                        v-bind="attrs"
                                    >fal fa-users</v-icon>
                                </template>
                                <span>Public</span>
                            </v-tooltip>
                        </div>
                    </template>
                </div>
                <div v-if="item.children && item.children.length > 0" class="tw-bg-gray-100 tw-border-0 tw-border-b tw-border-solid tw-border-gray-200">
                    <conversation
                        v-for="reply in item.children"
                        :key="reply.id"
                        :item="reply"
                        :update-url="`conversation-items/${reply.id}`"
                        :soft-delete-url="`conversation-items/${reply.id}/soft-delete`"
                        :parent="item"
                        @showParentReplyForm="showReplyForm = true"
                        :mailboxes="mailboxes"
                        :recipientsOptions="recipientsOptions"
                        :ticket="ticket"
                        @commentFormOpened="handleCommentFormOpened"
                    />
                </div>
                <div
                    class="tw-bg-white tw-rounded-b tw-p-2"
                    v-if="parent === null"
                >
                    <comment-form
                        :mailboxes="mailboxes"
                        :recipientsOptions="recipientsOptions"
                        :ticket="ticket"
                        :parent="item"
                        @commentFormOpened="handleCommentFormOpened"
                    />
                </div>
            </div>
        </div>
    </v-hover>
</template>

<script>
import { mapGetters } from 'vuex';
import Form from '@/utils/form';
import Http from '@/utils/httpClient';

export default {
    components: {
        Conversation: () => import('./conversation.vue'),
        CommentForm: () => import('./comment-form.vue'),
        TinyEditor: () => import(/* webpackChunkName: "tiny-editor" */ '@/components/TinyEditor.vue'),
    },
    props: {
        mailboxes: {
            type: Array,
            required: true,
        },
        recipientsOptions: {
            type: Array,
            required: true,
        },
        ticket: {
            type: Object,
            required: true,
        },
        item: {
            type: Object,
            required: true,
        },
        updateUrl: {
            type: String,
            required: true,
        },
        softDeleteUrl: {
            type: String,
            required: true,
        },
        replyDisabled: {
            type: Boolean,
            default: false,
        },
        parent: {
            type: Object,
            default: null,
        }
    },
    data() {
        return {
            confirmDelete: false,
            updating: false,
            deleting: false,
            replying: false,
            updateSuccess: false,
            showReplyForm: false,
            showEditForm: false,
            editForm: new Form({
                body: null,
                staff_only: true,
            }),
        }
    },
    computed: {
        ...mapGetters(['me']),
        ...mapGetters({
            selectedConversation: 'ticket/selectedConversation',
        }),
        recipients() {
            if (this.item.creator) {
                return [this.item.creator.id_with_class];
            }

            return [];
        }
    },
    methods: {
        edit() {
            this.showReplyForm = false;

            if (this.selectedConversation.id === this.item.id && this.showEditForm) {
                this.$store.commit('ticket/selectConversation', {});
            } else {
                this.$store.commit('ticket/selectConversation', this.item);
            }

            this.editForm.set({
                body: this.item.description || this.item.comment,
                staff_only: this.item.staff_only,
            })

            this.showEditForm = true;
        },
        cancelEdit() {
            this.$store.commit('ticket/selectConversation', {});
        },
        update() {
            this.updating = true;
            Http.patch(this.updateUrl, this.editForm.payload())
                .then(() => {
                    this.updateSuccess = true;
                    const newItem = this.item;

                    if (this.parent) {
                        newItem.comment = this.editForm.body;
                    } else {
                        newItem.description = this.editForm.body;
                    }

                    newItem.staff_only = this.editForm.staff_only;

                    this.$store.commit('ticket/updateConversation', newItem);

                    setTimeout(() => {
                        this.updating = false;
                        this.updateSuccess = false;
                        this.showEditForm = false;
                    }, 1000)
                })
                .catch(({ response }) => {
                    this.updating = false;
                    this.editForm.errors.record(response.data);
                })
        },
        deleteConversation() {
            this.deleting = true;
            this.$store.dispatch('ticket/softDeleteConversation', {
                    id: this.item.id,
                    url: this.softDeleteUrl,
                    parentId: this.parent ? this.parent.id : null,
                })
                .then(() => {
                    this.deleting = false;
                    this.confirmDelete = false;
                })
                .catch(() => {
                    this.deleting = false;
                })
        },
        handleCommentFormOpened() {
            this.$emit('commentFormOpened');
        }
    }
}
</script>
